fieldset {
    border: 0;
    background: transparent;
}

input,
select,
textarea {
    padding: var(--input-btn-padding-y) var(--input-btn-padding-x);
    line-height: var(--input-btn-line-height);
    border-radius: var(--input-btn-border-radius);
    border: var(--input-btn-border-size) var(--input-btn-border-style) var(--input-btn-border-color);
}

input,
select {
    font-size: var(--input-btn-font-size);
    min-height: calc( var(--input-btn-line-height) + 2 * var(--input-btn-padding-y) ); 
}

.form {
    &-block {
        display: block;
        width: 100%;
    }
    &-label {
        display: inline-block;
        font-size: 1rem;
        margin-bottom: 0.325rem;
    }

    &-control {
        width: 100%;
        display: block;
        padding: var(--input-btn-padding-y, 0.375rem) var(--input-btn-padding-x, 0.75rem);
        font-family: var(--input-btn-font-family);
        font-size: var(--input-btn-font-size, 1rem);
        font-weight: var(--input-btn-font-weight, 400);
        line-height: var(--input-btn-line-height, 1.5);
        color: var(--input-btn-color, #212529);
        background: var(--input-btn-bg, #fff);
        border-radius: var(--input-btn-border-radius, 0.25rem);
        background-clip: padding-box;
        border: var(--input-btn-border);
        appearance: none;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    &-select {
        display: block;
        width: 100%;
        padding: var(--select-padding);
        font-family: var(--select-font-family);
        font-size: var(--select-font-size);
        font-weight: var(--select-font-weight, 400);
        line-height: var(--select-line-height, 1.5);
        color: var(--select-color, #212529);
        background: var(--select-bg);
        border: 1px solid #ced4da;
        border-radius: var(--select-border-radius, 0.25rem);
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        appearance: none;
    }

    &-check {
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5em;
        margin-bottom: 0.125rem;

        &-input {
          display: block;
          min-height: 1.5rem;
          padding-left: 1.5em;
          margin-bottom: 0.125rem;

          &[type=checkbox] {
            border-radius: 0.25em;
          }
        }

        & &-input {
          float: left;
          margin-left: -1.5em;
        }

        &-label {}
    }

    &-range {
      padding: 0;
      width: 100%;
      height: 1.5rem;
    }
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

     > .form-control,
     > .form-select,
     > .form-range {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;

        &:not(:last-child){
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:not(:first-child){
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
    
    }

    > .btn {
      position: relative;
      z-index: 2;

      &:not(:last-child){
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:not(:first-child){
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
}

/** Custom Form controls */
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;

  &-input{
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
  }

  &-label {
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    line-height: 1.5;

    &::before {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: #adb5bd solid 1px;

      .custom-switch & {
        left: -2.25rem;
        width: 1.75rem;
        pointer-events: all;
        border-radius: 0.5rem;
      }  

      .custom-control-input:checked ~ & {
        color: #fff;
        border-color: #007bff;
        background-color: #007bff;
      }

    }

    &::after{
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      content: "";
      background: 50% / 50% 50% no-repeat;

      .custom-switch & {
        top: calc(0.25rem + 2px);
        left: calc(-2.25rem + 2px);
        width: calc(1rem - 4px);
        height: calc(1rem - 4px);
        background-color: #adb5bd;
        border-radius: 0.5rem;
        transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
        transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
      }

      .custom-switch .custom-control-input:checked ~ & {
        background-color: #fff;
        transform: translateX(0.75rem);
      }
    }
  }
}

.custom-switch {
  padding-left: 2.25rem;
}