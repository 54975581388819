
body {
    font: var(--body-font);
    color: var(--body-color, #212529);
    text-align: left;
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font: var(--heading-font);
}