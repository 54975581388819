:root {
  --lessons-bg-color: #081438;
  --lessons-bg: var(--lessons-bg-color) url(/assets/bkg-lessons-footer.svg) no-repeat center bottom / contain;
  --lessons-color: white;
  --lessons-font-style: var(--sys-font-style);
  --lessons-font-size: var(--sys-font-size);
  --lessons-font-weight: var(--sys-font-weight);
  --lessons-font-line-height: var(--sys-font-line-height);
  --lessons-font-family: var(--sys-font-family);
  --lessons-font:
    var(--lessons-font-style)
    var(--lessons-font-weight)
    var(--lessons-font-size) / var(--lessons-font-line-height)
    var(--lessons-font-family);

  --lessons-heading-color: var(--lessons-color, white);
  --lessons-heading-font-weight: var(--sys-heading-font-weight, 600);
  --lessons-heading-font-line-height: 1;
  --lessons-heading-font-family: var(--sys-heading-font-family);

  --lessons-btn-bg: #008C32;
  --lessons-continue-bg: rgba(0, 0, 0, 0.20);

  --lessons-skills-header-bg: #5B2E89;
  --lessons-skills-header-radius: 0.3125rem;
  --lessons-skills-header-font-family: var(--lessons-heading-font-family);
  --lessons-skills-header-font-size: 2rem;
  --lessons-skills-header-line-height: 1;
  --lessons-skills-header-font: 
    var(--lessons-skills-header-font-weight, 600)
    var(--lessons-skills-header-font-size, 2rem) / var(--lessons-skills-header-line-height, 1)
    var(--lessons-skills-header-font-family);

  --lessons-banner-bg-color: #341C54;
  --lessons-banner-bg-img: url(/assets/bkg-lessons-header.webp);
  --lessons-banner-bg: var(--lessons-banner-bg-color) var(--lessons-banner-bg-img) no-repeat center / cover;

  --lessons-progress-bg: #001C3B;
  --lessons-progress-meter: repeating-linear-gradient(
    -45deg,
    #37C6AC,
    #37C6AC 0.25rem,
    #D8FB00 0.25rem,
    #D8FB00 0.5rem
  );
  --lessons-progress-meter-complete: #53C50E;

  --lessons-series-thumb-height: 9rem;
  --lessons-series-card-bg: #00244D;
  --lessons-series-card-radius: 1.25rem;
  --lessons-series-card-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.25);

  // Series
  --series-banner-bg-color: var(--lessons-banner-bg-color);
  --series-banner-bg: var(--series-banner-bg-color);
  --episode-card-bg-color: #003269;
  --episode-card-num-bg-color: #00438B;
  --episode-card-num-text-color: #B3FFC8;
  --episode-card-num-box-shadow: 0px 0.3px 1px 0px rgba(0, 0, 0, 0.20),
                                  0px 1px 2px 0px rgba(0, 0, 0, 0.25),
                                  0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset;
  --episode-card-focus-color: #5c23ff;

  // Preview Screen
  --preview-learning-target-bg-color: white;
  --preview-learning-target-color: black;

  --preview-bar-color: #FAFCFF;
  --preview-bar-completed-bg: #3CA1FF;
  --preview-bar-completed-path-bg: #0482FF;
  --preview-bar-completed-video-shadow: 0px 0.5px 0px 1px #007FF4;
  --preview-bar-completed-question-shadow: 0px 1px 0px 0px #007FF4;
  --preview-bar-incomplete-bg: #0061CB;
  --preview-bar-incomplete-path-bg: #00438B;
  --preview-bar-incomplete-question-border: 4px solid #0050A6;
  --preview-bar-incomplete-question-shadow:
      0px 0.5px 0px 1px rgba(0, 33, 72, 0.50) inset,
      0px 1px 0px 0px #003484,
      0px 1px 0px 0px #003484;
  --preview-bar-current-bg: #0482FF;
  --preview-bar-current-border: 4px solid #FFD439;
  --preview-bar-current-shadow:
      0px 4px 8px 0px rgba(0, 0, 0, 0.25) inset,
      0px 1px 0px 0px #D7A901 inset,
      0px 0.5px 0px 4px rgba(255, 178, 74, 0.85),
      0px 1px 0px 0px #EAB600;
  --preview-bar-current-question-shadow: 0px 1px 0px 0px #007FF4;

  --assignments-banner-bg: var(--lessons-banner-bg-color) url(/assets/bkg-assignments-header.png) no-repeat center / cover;

}

// TODO: Remove this when series name is removed from assignment-card-current and preview modal
.assignment-series-name {
  font-size: 1.125rem;
  line-height: 1.25;
  font-weight: 400;
  color: var(--sky-200) !important;
  margin: 0 0 0.5rem 0;
  letter-spacing: 0.03em;
  &.series-icon {
    padding-left: 1.75rem !important;
    background: transparent url('/assets/icons/series-stack.svg') no-repeat left top / 1.5rem 1.25rem;  
  }
}

.lessons, .series, .assignments {
  --border-radius: 1.25rem;
  font: var(--lessons-font);
  color: var(--lessons-color, white);
  background: var(--lessons-bg, #081438);
  padding-bottom: 30.5rem;

  h1, 
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--lessons-heading-font-family);
    font-weight: var(--lessons-heading-font-weight, 600);
    color: var(--lessons-heading-color, white);
  }

  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  header {
    width: 100%;
  }

  &-none {
    max-width: 60rem;
    text-align: center;
    margin: 0 auto;
    padding: 3.25rem 0 1.875rem;
  
    svg-icon {
      display: block;
      width: 17.375rem;
      max-width: 100%;
      height: auto;
      margin: 0 auto 2rem;
    }
  }

  .none-label-primary,
  .none-label-secondary {
    color: #B491E1;
  }

  .none-label-primary {
    font-weight: 700;
    font-size: 2.375rem;
    margin-bottom: 0.5rem;
  }

  .none-label-secondary {
    font-size: 1.25rem;
    font-style: 400;
  }

  .card-shadow::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    border-radius: var(--border-radius);
    height: 100%;
    box-shadow: inset 0 -.4rem rgba(0, 36, 77, 0.4);
    pointer-events: none;
    mix-blend-mode: hard-light;
  }
}

.lessons, .assignments {
  h1, 
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
    margin: 0;
    text-align: left;
  }

  header {
    max-width: 60rem;
    margin: 0 auto 1rem;
    text-align: left;
  }
}

swe-series .nav-back-button .button-text {
  display: none;
  @media screen and (min-width: 48rem) {
    display: inline-block;
  }
}

#dialog-preview .dialog {
  color: white;
  * {
    // scrollbar-color: #9333EA #003E80;
    scroll-padding: 1.5rem;
  }
  ::-webkit-scrollbar {
    width: 1.25rem;
  }
  ::-webkit-scrollbar-track {
    background: #003E80;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(rgb(59, 130, 246), rgb(147, 51, 234));
    box-shadow: 
      inset 2px 2px 2px rgba(255, 255, 255, 0.25),
      inset -2px -2px 2px rgba(0, 0, 0, 0.25); 
    border-radius: 3.125rem;
    border: 4px solid #003E80;

    &:hover {
      cursor: pointer;
      background: #5749d2;
    }
  }

  h2, h3, h4, h5 ,h6 {
    color: inherit;
    font-family: var(--lessons-font-family);
  }

  &-content {
    background: var(--episode-card-bg-color, #003269);
  }

  &-header {
    padding: 1rem;

    h2 {
      font-size: 1.5rem;
      line-height: 1.25;
    }

    span {
      display: inline-block;
      padding: 0.5rem;
      border-radius: 1rem;
      background: var(--episode-card-num-bg-color, #00438B);
      box-shadow: 0px 0.3px 1px 0px rgba(0, 0, 0, 0.20),
                  0px 1px 2px 0px rgba(0, 0, 0, 0.25),
                  0px -1px 0px 0px rgba(0, 0, 0, 0.25) inset;
      font-size: 1.5rem;
      color: var(--episode-card-num-text-color, #B3FFC8);
      min-width: 4.25rem;
      text-align: center;
      line-height: 1;
      margin-bottom: 1px;
      margin-right: 1rem;
    }

    .btn-close {
      background: transparent;
      color: white;
    }
  }

  &-body {
    padding: 1.5rem;
    background: var(--episode-card-num-bg-color, #00438B);
  
    h2, h3, h4, h5 ,h6 {
      margin: 0;
    }  
  }

  &-footer {
    justify-content: space-between;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    padding: 1.5rem;

    h6 {
      font-size: 1.125rem;
      line-height: 1.25;
      margin-bottom: 0.25rem;
      margin: 0;
      font-weight: 400;
    }

    .episode-status {
      flex-basis: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      padding: 0.75rem 1rem;
      border-radius: 0.625rem;
      background: rgba(0, 0, 0, 0.10);

      swe-preview-progress-bar {
        width: 100%;
        flex: 1 1 auto;
        padding: 0 1.5rem;
      }

      > span {
        text-align: left;
        display: inline-block;
        flex: 1 0 8rem;

        &.episode-score {
          padding-left: 1.5rem;
          flex-basis: 5.625rem;
        }

        :last-child {
          font-weight: 600;
        }
      }
    }

    .episode-score {
      font-weight: 600;
      flex: 0 0 8rem;
      border-left: 1px solid transparentize(#fff, 0.9);
    }

    .btn-group {
      flex-flow: column nowrap;
      gap: 1.5rem;
      width: 100%;
      padding: 0;
    }

    .btn {
      display: flex;
      gap: .5rem;
      padding: 1rem 1.5rem;
      border-radius: 6.25rem;
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      flex: 1;
      width: 100%;

      &-primary {
        background: var(--lessons-btn-bg, #008C32);
        box-shadow: 0px 8px 0px 0px #00552B;  
      }

      &-secondary {
        background: #006CDB;
        box-shadow: 0px 8px 0px 0px #004EA3, 0px 8px 0px 0px #004086;
      }

      & ~ .btn {
        margin: 0;
      }

      svg-icon {
        height: 1.5rem;
        width: 1.5rem;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 48rem) {
    &-header span {
      font-size: 2.5rem;
    }

    &-body {
      position: static;
    }

    &-footer {
      display: flex;
      align-items: center;
      flex-flow: row wrap;

      .btn {
        flex: 0 1 auto;
        width: auto;
      }

      .btn-group {
        flex-flow: row nowrap;
        margin-left: auto;
        width: auto;
      }
    }
  }

  @media screen and (min-width: 68.75rem ) {
    &-header h2 {
      font-size: 2rem;
    }
    &-content {
      max-width: 68.75rem;
    }
  }
}