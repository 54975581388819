button:not(:disabled,[disabled]),
.btn:not(:disabled,[disabled]),
[type=button]:not(:disabled,[disabled]),
[type=reset]:not(:disabled,[disabled]),
[type=submit]:not(:disabled,[disabled]) {
  cursor: pointer;
}

//--- Disabled ---//

[disabled],
:disabled,
.btn:disabled,
.btn[disabled],
.btn[aria-disabled="true"] {
  cursor: not-allowed;
  opacity: 0.7;
  filter: saturate(0.2) grayscale(0.9);

  &:before {
    opacity: 0.7;
    filter: saturate(0.2) grayscale(0.9);
  }

  &:after {
    background: var(--btn-bg-color);
    border-color: var(--btn-bg-color);
    opacity: 1;
    filter: saturate(0.2) grayscale(0.9);
  }

  span,
  svg {
    filter: saturate(0.2) grayscale(0.9);
    opacity: 0.7;
  }

  &:active {
    @media (prefers-reduced-motion: no-preference) {
        animation: shake 0.3s 3 ease-out;
        animation-fill-mode: both;
    }
  }
}

@mixin pseudo-layer {
  /* mixin to provide scaffold for button "layers". This gets used in btn:before and btn:after */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  content: "";
}

@mixin hover {
  /* Made this a mixin because it's also included in the focus styles */
  border: var(--btn-border-size) solid var(--btn-border-hover-color);
  transform: translateY(var(--btn-hover-distance)) scale(var(--btn-hover-scale));
  transition: all 100ms cubic-bezier(0.3, 0.7, 0.4, 1.5);

  &:after {
    opacity: 1;
    transition: opacity 80ms ease-in;
    border: var(--btn-hover-inset-width) solid var(--btn-hover-inset-color);
  }
}

@mixin pseudo-layer {
  /* mixin to provide scaffold for button "layers". This gets used in btn:before and btn:after */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  content: "";
}

@mixin hover {
  /* Made this a mixin because it's also included in the focus styles */
  border: var(--btn-border-size) solid var(--btn-border-hover-color);
  transform: translateY(var(--btn-hover-distance)) scale(var(--btn-hover-scale));
  transition: all 100ms cubic-bezier(0.3, 0.7, 0.4, 1.5);

  &:after {
    opacity: 1;
    transition: opacity 80ms ease-in;
    border: var(--btn-hover-inset-width) solid var(--btn-hover-inset-color);
  }
}

.btn {
  /* basic reset stuff */
  appearance: none;
  text-decoration: none;
  white-space: nowrap;

  /* stuff to make the button behave nicely on touchscreens */
  user-select: none;

  display: inline-flex;
  font: var(--btn-font);
  color: var(--btn-color);
  border-radius: var(--btn-border-radius);
  border: var(--btn-border);
  box-shadow: var(--btn-utility-shadow-1), var(--btn-utility-shadow-2), var(--btn-3d-bg), var(--btn-shadow);
  padding: var(--btn-padding-y) var(--btn-padding-x);
  background-color: var(--btn-bg-color);
  background-image: var(--btn-bg-image);
  background-position: var(--btn-bg-position);
  background-size: var(--btn-bg-size);
  background-repeat: var(--btn-bg-repeat);
  justify-content: center;
  align-items: center;
  text-align: var(--btn-text-align);
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  gap: .5rem;

  /* prep for interactions */
  will-change: transform; /* scale transition animation is jumpy without this */
  transition: transform 300ms cubic-bezier(0.3, 0.7, 0.4, 1); /* smooth transition on mouse leave. Without this, mouse leave from the hover state is abrupt */
  transform-style: preserve-3d;
  transform-origin: bottom;

  &::after {
    // This layer appears in FRONT of .btn //
    @include pseudo-layer;
    z-index: -1;
    color: inherit;
    filter: none;
    background: transparent;
    border: var(--btn-border-size) solid var(--btn-border-color);
    border-radius: inherit;
    opacity: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited,
  &[disabled],
  &:disabled {
      text-decoration: none;
  }

  @media (any-hover: hover) {
    &:hover:not(:disabled, [aria-disabled="true"]) {
      @include hover;
    }
  }

    //--- Focus ---//

  &:focus {
    /* fallback */
    outline: var(--btn-focusring-color) 3px solid;
  }

  @supports selector(:focus-visible) {
    .btn:focus {
      outline: none;
    }
  }

  &:focus-visible {
    @include hover;
    outline: transparent 4px solid;
    outline-offset: 3px;

    &:after {
      opacity: 1;
      box-shadow:
        /* A */ 0 0 0 var(--btn-border-width, 4px)
          var(--btn-border-hover-color),
        /* B */ 0 0 0 calc(var(--btn-border-width) + 4px)
          var(--btn-focus-ring-color),
        /* C */ 0 0 0 calc(var(--btn-border-width) + 7px) rgba(255, 255, 255, 1);

      // Explanation of box-shadow stack:
      // A: this mimics the button border hover style. It's not ideal but I had to add this because otherwise the focus ring covers up the button's actual border. Kinda hard to explain. There may be better ways to handle this.
      // B: The outer focus ring, the "dark" color. currently a red color
      // C: The inner focus ring, the "light" color. currently white
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:active:not(:disabled, [aria-disabled="true"]) {
    box-shadow: var(--btn-utility-shadow-1), var(--btn-utility-shadow-2), var(--btn-3d-bg-press), var(--btn-shadow-press);
    transform: translateY(var(--btn-press-distance)) scale(var(--btn-press-scale));
    transition: all 150ms cubic-bezier(.17,.87,.44,1);

    &:after {
      background-color: var(--btn-bg-color);
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 1;
      transition: opacity 150ms cubic-bezier(.17,.87,.44,1);
      border: var(--btn-hover-inset-width) solid var(--btn-hover-inset-color);
    }
  }

  &-group {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      padding: 0.75rem 0;
      margin-left: -0.75rem;
      margin-right: -0.75rem;

      .btn {
          margin: 0 0.75rem;
      }

  }

  svg-icon {
    display: flex;
    align-self: center;
    min-width: 1em;
    height: 1em;
  }

  &-action {
      width: var(--btn-action-width);
      height: var(--btn-action-height);
      border-radius: calc( var(--btn-action-width) / 2 );
      padding: var(--btn-action-padding-y) var(--btn-action-padding-x);
  }

	&-link,
    .sys &-link {
		color: var(--link-color);
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
		text-decoration: none;
		font-weight: inherit;
		font-style: normal;
		font-size: 14px;
		font-family: var(--body-font);
		line-height: 16px;

    &:hover,
    &:focus,
    &:active{
      text-decoration: underline;

      &:after {
        border: none;
        background: none;
      }
    }
	}

  &-close,
  &-back,
  .sys &-close {
    width: 3.875rem;
    height: 4.375rem;
    line-height: 2rem;
    background: white;
    border: 0;
    overflow: hidden;
    text-indent: -999em;
    padding: 1.1875rem;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      background-color: #e9e8ff;
    }
  }

  &-shiny {
      &,  /* default is green, maybe? */
      &-green {
          --color-main: #008C32;
          --color-outline: #00FF64;
          --color-shadow: #00552B;
      }
      &-blue {
          --color-main: #006CDB;
          --color-outline: #00D8FF;
          --color-shadow: #004086;
      }
  }

  &-more {
    justify-content: space-between;
  }

  /* starting style */
  footer & ~ .btn {
    margin-top: 1rem;

    /* override at >= 720px breakpoint */
    @media (min-width: 45rem) {
      margin-top: 0;
      margin-left: 3rem;
    }
  }
}

/* disabled feedback animation */

$shakeLeft: translateX(-3px) rotate(-1deg);
$shakeRight: translateX(3px) rotate(0deg);

@keyframes shake {
  0%,
  100% {
    transform: $shakeRight;
  }
  10% {
    transform: $shakeLeft;
  }
  20% {
    transform: $shakeRight;
  }
  30% {
    transform: $shakeLeft;
  }
  40% {
    transform: $shakeRight;
  }
  50% {
    transform: $shakeRight;
  }
  60% {
    transform: $shakeLeft;
  }
  70% {
    transform: $shakeRight;
  }
  80% {
    transform: $shakeLeft;
  }
  90% {
    transform: $shakeRight;
  }
}
