*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%; 
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    height: 100%;
}
html,
body {
    padding: 0;
    margin: 0;
}