@use 'sass:selector';

/** 
This is a super class that allows us to turn off theme styling for System UI
*/
.sys {
  --input-btn-font-family: var(--sys-font-family);
  --input-btn-font-weight: var(--sys-font-weight);
  --btn-focus-outline-color: rgba(255, 51, 102, 0.9);

  font: var(--sys-font-weight) var(--sys-font-size) /
    var(--sys-font-line-height) var(--sys-font-family);
  color: var(--sys-color);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--sys-heading-font-family);
    font-weight: var(--sys-heading-font-weight);
    color: var(--sys-heading-color);
  }

  p {
    font-size: 1rem;
    line-height: 1.125rem;
    color: inherit;
  }

  .btn {
    font: normal 700 1.5rem/1.17 var(--sys-heading-font-family);
    padding: 0.5em 1.375em;
    border-radius: 6.25rem;
    box-shadow: none;
    transform: translate(0, 0);

    &:hover,
    &:focus,
    &:active,
    &[disabled],
    &:disabled {
      box-shadow: none;
    }

    &-primary {
      color: #fff;
      background: #1e0065;
      border: 2px solid transparent;

      &:hover,
      &:focus,
      &:active,
      &[disabled],
      &:disabled {
        box-shadow: none;
      }

      &:hover,
      &:focus,
      &:active {
        color: #1e0065;
        border: 2px solid #1e0065;
        background: #e9e8ff;
      }

      &[disabled],
      &:disabled {
        color: #888;
        border: 1px solid #888;
        background: #ddd;
      }
    }

    &-secondary {
      color: #000;
      background: #c1bfff;
      border: 2px solid transparent;

      &:hover,
      &:focus,
      &:active,
      &[disabled],
      &:disabled {
        box-shadow: none;
      }

      &:hover,
      &:focus,
      &:active {
        color: #1e0065;
        border: 2px solid #1e0065;
        background: #e9e8ff;
      }

      &[disabled],
      &:disabled {
        color: #888;
        border: 1px solid #888;
        background: #ddd;
      }
    }
  }

  &-landing {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 60rem;
    padding-top: 10vh;
    margin: 0 auto;

    figure {
      display: block;
      margin: 0 0 2.5rem 0;
      padding: 0;
      position: relative;
      top: 0;
      width: 100%;
      max-width: 177px;
      height: 159px;

      svg-icon {
        width: 100%;
        height: 100%;
        margin: 0;
      }

      img {
        position: absolute;
        top: 75%;
        left: 50%;
        width: 65%;
        height: auto;
        transform: translate(-50%, -50%);
      }
    }

    &.student-inbox {
      figure {
        left: 0;
      }
    }

    h1,
    .btn-link {
      font-weight: var(--sys-font-weight);
    }

    h1 {
      font-family: var(--sys-font-family);
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }

    .btn {
      svg-icon {
        display: inline-block;
        width: 1.875rem;
        height: 1.875rem;
        max-width: 1em;
        max-height: 1em;
        margin-right: 0.7em;
        color: inherit;

        svg {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &-link {
        margin-top: 2.5rem;
        font-size: 1.3125rem;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0;
        line-height: 1.14;
      }
    }
  }

  @at-root #{selector.unify(&, 'swe-toast')} {
    z-index: var(--z-index-toast);
    svg-icon {
      display: inline-block;
      height: 3em;
      width: 3em;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .toast-message {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.25em;
      width: 100%;

      span {
        display: block;
      }

      .countdown {
        font-size: 0.8375em;
        color: transparentize(#000000, 0.3);
      }
    }

    .btn {
      color: rgb(127, 70, 24);
      font-size: 1rem;
      border: 0;
      background: none;
      padding: 0;
      font-weight: 600;
      margin-left: 0.75rem;

      &::after {
        visibility: hidden;
      }

      &:hover {
        border: 0;
        box-shadow: none;
      }
    }
  }
}
