.wrapper {
    overflow-y: auto;
    height: 100%;
    min-height: 100%;
    margin: 0;

    swe-nav ~ & {
        padding-top: var(--nav-height, 5rem);
        height:100vh;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;  
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.compatibility {
    &-message {
        display: block;
        text-decoration: none !important;
        text-align: left;
        font-weight: normal;
        font-size: .9rem;
        max-width: 40rem;
        margin: auto;
    }

    &-warning {
        color: #6072a4;
    }

    &-error {
        color: #d12a2d;
    }

    &-hidden {
        display: none;
    }
}

.text-muted {
    color: #6F6F6F;
}